<template>
  <div>
    <div class="row" v-if="Object.keys(admin_scenario_issue_detail).length !== 0">
      <div class="col-md-12 mb-2">
        <a-button :loading="btnIssueAccepted" v-if="admin_scenario_issue_detail.admin_scenario_issue_status.includes('ISSUE_PENDING') && !admin_scenario_issue_detail.admin_scenario_issue_status.includes('ISSUE_IN_PROGRESS_BY_SOLVER') && !admin_scenario_issue_detail.admin_scenario_issue_status.includes('ISSUE_RETURN_BY_SOLVER')"  class="btn btn-success btn-sm mr-2 float-right" @click.prevent="adminIssueAccepted(admin_scenario_issue_detail.id)"><i class="fa fa-check mr-1"></i>  Issue Accept</a-button>
        <a-button :loading="btnIssueRemoved" v-if="admin_scenario_issue_detail.admin_scenario_issue_status.includes('ISSUE_PENDING') && !admin_scenario_issue_detail.admin_scenario_issue_status.includes('ISSUE_IN_PROGRESS_BY_SOLVER') && !admin_scenario_issue_detail.admin_scenario_issue_status.includes('ISSUE_RETURN_BY_SOLVER')" class="btn btn-danger btn-sm mr-2 float-right" @click.prevent="adminIssueRemove(admin_scenario_issue_detail.id)"><i class="fa fa-close mr-1"></i>  Issue Cancel</a-button>
        <a-button :loading="btnIssueSolved" v-if="admin_scenario_issue_detail.admin_scenario_issue_status.includes('ISSUE_IN_PROGRESS_BY_SOLVER') && !admin_scenario_issue_detail.admin_scenario_issue_status.includes('ISSUE_SOLVED_BY_SOLVER') && !admin_scenario_issue_detail.admin_scenario_issue_status.includes('ISSUE_RETURN_BY_SOLVER')" class="btn btn-success btn-sm mr-2 float-right" @click.prevent="adminIssueSolved(admin_scenario_issue_detail.id)"><i class="fa fa-check-circle mr-1"></i> To Solved Issue Click Here </a-button>
      </div>
      <admin-scenario-issue-hub-detail :admin_scenario_issue="admin_scenario_issue_detail"></admin-scenario-issue-hub-detail>
      <admin-scenario-issue-header-status :current="current"  :admin_scenario_issue="admin_scenario_issue_detail"></admin-scenario-issue-header-status>
      <div class="col-md-12">
        <div class="card">
          <a-spin :spinning="spinning" :delay="delayTime">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3"><b>Admin Scenario Accessory:</b></div>
                <div class="col-md-9">{{ admin_scenario_issue_detail.admin_scenario_accessory.name }}</div>
                <div class="col-md-3"><b>Issue No.:</b></div>
                <div class="col-md-9">{{ admin_scenario_issue_detail.issue_no }}</div>
                <div class="col-md-3"><b>Issue Description: </b></div>
                <div class="col-md-9"> {{ admin_scenario_issue_detail.issue_description }}</div>
                <div class="col-md-3" v-if="admin_scenario_issue_detail.admin_scenario_accessory_id === 5"><b>Staff Name: </b></div>
                <div class="col-md-9" v-if="admin_scenario_issue_detail.admin_scenario_accessory_id === 5"> {{ admin_scenario_issue_detail.staff ? admin_scenario_issue_detail.staff.name : '' }}</div>
                <div class="col-md-3"><b>Issue Image: </b></div>
                <div class="col-md-9">
                <span v-if="admin_scenario_issue_detail.issue_image" class="ant-badge">
                    <a :href="admin_scenario_issue_detail.admin_scenario_issue_image_url" target="_blank" download>
                      <img :src="admin_scenario_issue_detail.admin_scenario_issue_image_url" alt=""  class="rounded mx-auto d-block"  width="200" height="200"/>
                    </a>
                </span>
                </div>
              </div>
            </div>
            <!--comment div-->
            <admin-scenario-comment
              :admin_scenario_issue_id="admin_scenario_issue_detail.id"
              :comments="admin_scenario_issue_detail.admin_scenario_issue_comments"
              :users="users"
              @getAdminScenarioIssue="getAdminScenarioIssue"
            ></admin-scenario-comment>
          </a-spin>
        </div>
      </div>
    </div>
    <div v-else>
      <a-skeleton active :loading="loading"></a-skeleton>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import adminScenarioComment from '@/views/adminScenario/issue/partial/admin-scenario-comment'
import adminScenarioIssueHubDetail from '@/views/adminScenario/issue/partial/admin_scenario_issue_hub_detail'
import adminScenarioIssueHeaderStatus from '@/views/adminScenario/issue/partial/admin_scenario_issue_header_status'

export default {
  name: 'issue_details',
  components: { adminScenarioComment, adminScenarioIssueHubDetail, adminScenarioIssueHeaderStatus },
  data() {
    return {
      admin_scenario_issue_detail: {},
      users: [],
      loading: false,
      btnIssueAccepted: false,
      btnIssueRemoved: false,
      btnIssueSolved: false,
      spinning: false,
      delayTime: 500,
      current: 0,
      message: 'Loading...',
    }
  },
  mounted() {
    this.getUsers()
    this.getAdminScenarioIssue()
  },
  methods: {
    getUsers() {
      apiClient.get('api/comment/users')
        .then(response => {
          const users = response.data.users
          users.map(function (user) {
            // eslint-disable-next-line no-return-assign
            return user.user_data = user.name + ' - ' + user.phone_no
          })
          this.users = users
        })
        .catch(error => {
          console.log(error)
        })
    },
    customDate(date) {
      return moment(date).format('LLL')
    },
    getAdminScenarioIssue() {
      const adminScenarioIssueId = this.$route.params.admin_scenario_issue_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/admin-scenario/admin/issue/details/' + adminScenarioIssueId)
        .then(response => {
          this.loading = false
          this.spinning = false
          if (!response.data.error) {
            const data = response.data
            this.admin_scenario_issue_detail = data.admin_scenario_issue_detail
            this.current = data.max_admin_scenario_issue_status
          } else {
            this.message = response.data.message
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    adminIssueAccepted(adminScenarioIssueId) {
      if (confirm('Do you really want to accept?')) {
        this.btnIssueAccepted = true
        apiClient.post('api/admin-scenario/admin/issue/accepted', { admin_scenario_issue_id: adminScenarioIssueId })
          .then(response => {
            this.btnIssueAccepted = false
            this.$notification.success({
              message: response.data.message,
            })
            this.getAdminScenarioIssue()
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    adminIssueRemove(adminScenarioIssueId) {
      if (confirm('Do you really want to remove?')) {
        this.btnIssueRemoved = true
        apiClient.post('api/admin-scenario/admin/issue/rejected', { admin_scenario_issue_id: adminScenarioIssueId })
          .then(response => {
            this.btnIssueRemoved = false
            this.$notification.success({
              message: response.data.message,
            })
            this.getAdminScenarioIssue()
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    adminIssueSolved(adminScenarioIssueId) {
      if (confirm('Do you really want to solved?')) {
        this.btnIssueSolved = true
        apiClient.post('api/admin-scenario/admin/issue/solved', { admin_scenario_issue_id: adminScenarioIssueId })
          .then(response => {
            this.btnIssueSolved = false
            this.$notification.success({
              message: response.data.message,
            })
            this.getAdminScenarioIssue()
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
</style>
